<template>
  <div class="description-container">
    <h2>
      Comprehensive Commercial Cleaning and Carpet Care in Snohomish and King
      County Areas.
    </h2>
    <p>
      At <bold>Imperial Solution</bold>, we specialize in providing high-quality
      cleaning services for offices, commercial buildings, and carpets, tailored
      to the specific needs of each client. With a focus on attention to detail,
      professionalism, and advanced technology, we ensure clean, safe, and
      healthy environments. Our commitment is to deliver efficient and reliable
      services that raise the standards of cleanliness in your workspace.
    </p>
    <div class="CTA">
      <button @click="scrollToContact">FREE QUOTE!!</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "description",
  methods: {
    scrollToContact() {
      const contactSection = document.getElementById("contact-section");

      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.description-container {
  background-color: #000000;
  color: #ffffff;
  padding: 20px;

  h2 {
    text-align: center;
    padding-bottom: 20px;
  }
  p {
    text-align: center;
    padding-bottom: 20px;
  }

  .CTA {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: 20px;
      padding: 10px;
      width: 200px;
      height: 50px;
      border-radius: 5px;
      box-shadow: -10px -10px 0 0 rgba($color: #eeb84f, $alpha: 1);
      background-color: #ffffff;
      color: #000000;
      font-size: medium;
      font-weight: 700;
    }

    button:hover {
      box-shadow: -10px -10px 0 0 rgba($color: #ffffff, $alpha: 1);
      background-color: #eeb84f;
    }
  }
}
</style>
