<template>
  <div class="rooms-data">
    <div class="header">
      <p>Rooms</p>
      <div class="components">
        <p>QTY:</p>
        <font-awesome-icon icon="plus" />
        <img src="" alt="icon-less" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rooms",
};
</script>

<style lang="scss" scoped></style>
