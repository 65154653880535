<template>
  <div class="footer">
    <p>&copy; 2024 Imperial Solution. All rights reserved.</p>
  </div>
</template>

<script>
export default {
  name: "footer",
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  padding: 20px;
  width: 100%;
  height: auto;
  background-color: #000000;
  color: #ffffff;
  justify-content: center;
  align-items: center;
}
</style>
