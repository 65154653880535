<template>
  <div class="cabecera">
    <picture>
      <source
        srcset="../assets/WebP-images/imperial-solution-logo.webp"
        type="image/webp"
      />
      <img
        src="../assets/png-images/imperial-solution-logo.png"
        alt="logo imperial solution"
        width="255px"
        height="90px"
        loading="lazy"
      />
    </picture>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Cabecera",
};
</script>

<style lang="scss" scoped>
.cabecera {
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  img {
    max-width: auto;
    max-height: 90px;
  }
}
</style>
