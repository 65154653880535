<!-- src/views/Cotizador.vue -->
/* eslint-disable */
<template>
  <Cabecera />
  <PersonalData />
  <Rooms />
</template>

<script>
import Cabecera from "@/components/Cabecera.vue";
import PersonalData from "@/components/personal-data.vue";
import Rooms from "@/components/rooms.vue";

export default {
  // eslint-disable-next-line
  name: "Cotizador",
  components: {
    Cabecera,
    PersonalData,
    Rooms,
  },
};
</script>
