<template>
  <div class="personal-data">
    <div class="title">
      <p>Personal Data</p>
    </div>
    <div class="seccion">
      <div>
        <p>Nombre:</p>
        <input type="text" name="name" id="name" />
      </div>
      <div>
        <p>Apellido:</p>
        <input type="text" name="lastName" id="lastName" />
      </div>
    </div>
    <div class="seccion">
      <div>
        <p>Telefono:</p>
        <input type="tel" name="cellphone" id="cellphone" />
      </div>
      <div>
        <p>E-Mail:</p>
        <input type="email" name="e-mail" id="e-mail" />
      </div>
    </div>
    <div class="seccion">
      <div>
        <p>Calle:</p>
        <input type="text" name="address" id="address" />
      </div>
      <div>
        <p>Apartamento:</p>
        <input type="text" name="apartment" id="apartment" />
      </div>
      <div>
        <p>Codigo Postal:</p>
        <input type="number" name="zipCode" id="zipCode" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Personal-data",
};
</script>

<style lang="scss" scoped>
.personal-data {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 10px;
  margin: 20px;
  border-radius: 5px;
  box-shadow: #333 5px 5px 25px;

  .title {
    p {
      text-align: center;
      font-size: 24px;
    }
  }

  .seccion {
    display: flex;
    align-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 10px;

    div {
      padding: 10px;
      p {
        font-size: 16px;
      }
      input {
        width: 200px;
        padding: 10px;
        border-radius: 5px;
        border: 2px solid blue;
        font-size: 16px;
        color: #333;
        outline: none;
        transition: border-color 0.3s ease;
      }
      input:focus {
        border-color: #66cc75;
      }
    }
  }
}
</style>
