<template>
  <Cabecera />
  <div class="header">
    <picture>
      <source
        srcset="../assets/WebP-images/Clean-Office-photo.webp"
        type="image/webp"
      />
      <img
        src="../assets/png-images/Clean-Office-photo.jpeg"
        alt="Clean office picture"
        width="674px"
        height="260px"
        loading="lazy"
      />
    </picture>
  </div>
  <Description></Description>
  <div class="division"></div>
  <Values></Values>
  <div class="division"></div>
  <div class="services-container">
    <div class="title">
      <h2 class="services-title">SERVICES</h2>
    </div>
    <div class="services">
      <div class="service-container">
        <picture>
          <source
            srcset="../assets/WebP-images/Office-Cleaning.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Office-Cleaning.png"
            alt="office cleaning services"
            class="image"
            width="350px"
            height="350px"
            loading="lazy"
          />
        </picture>
        <picture>
          <source
            srcset="../assets/WebP-images/Office-Cleaning-back.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Office-Cleaning-back.png"
            alt="office cleaning description"
            class="image-hover"
            width="350px"
            height="350px"
            loading="lazy"
          />
        </picture>
      </div>
      <div class="service-container">
        <picture>
          <source
            srcset="../assets/WebP-images/Carpet-Cleaning.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Carpet-Cleaning.png"
            alt="Carpet Cleaning Service"
            class="image"
            width="350px"
            height="350px"
            loading="lazy"
          />
        </picture>
        <picture>
          <source
            srcset="../assets/WebP-images/Carpet-Cleaning-back.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Carpet-Cleaning-back.png"
            alt="Carpet cleaning description"
            class="image-hover"
            width="350px"
            height="350px"
            loading="lazy"
          />
        </picture>
      </div>
      <div class="service-container">
        <picture>
          <source
            srcset="../assets/WebP-images/Move-IN.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Move-IN.png"
            alt="Move-In Services, Move-Out Services"
            class="image"
            width="350px"
            height="350px"
            loading="lazy"
          />
        </picture>
        <picture>
          <source
            srcset="../assets/WebP-images/Move-IN-back.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Move-IN-back.png"
            alt="Move-IN Description & Move-Out Description"
            class="image-hover"
            width="350px"
            height="350px"
            loading="lazy"
          />
        </picture>
      </div>
      <div class="service-container">
        <picture>
          <source
            srcset="../assets/WebP-images/Post-Construction.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Post-Construction.png"
            alt="Post Construction Cleaning Services"
            class="image"
            width="350px"
            height="350px"
            loading="lazy"
          />
        </picture>
        <picture>
          <source
            srcset="../assets/WebP-images/Post-Construction-back.webp"
            type="image/webp"
          />
          <img
            src="../assets/png-images/Post-Construction-back.png"
            alt="Post Construction Cleaning Services description"
            class="image-hover"
            width="350px"
            height="350px"
            loading="lazy"
          />
        </picture>
      </div>
    </div>
  </div>
  <div class="division"></div>
  <Contact></Contact>
  <div class="division"></div>
  <Footer></Footer>
</template>

<script>
import Cabecera from "@/components/Cabecera.vue";
import Footer from "@/components/footer.vue";
import Contact from "@/components/contact.vue";
import Description from "@/components/Description.vue";
import Values from "@/components/Values.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "HomeView",
  components: {
    Cabecera,
    Footer,
    Contact,
    Description,
    Values,
  },
  setup() {
    useHead({
      title:
        "Imperial Solution | Servicios de Limpieza Comercial y de Alfombras | Commercial and Carpet Cleaning Services",
      meta: [
        // Meta description en español e inglés
        {
          name: "description",
          content:
            "Imperial Solution ofrece servicios de limpieza comercial y de alfombras en Bellevue, Seattle, y áreas cercanas. Imperial Solution offers commercial and carpet cleaning services in Bellevue, Seattle, and surrounding areas.",
        },
        // Keywords en español e inglés
        {
          name: "keywords",
          content:
            "limpieza comercial, limpieza de alfombras, limpieza de oficinas, limpieza profunda, servicios de janitorial, Bellevue, Seattle, Snohomish, Mount Vernon, commercial cleaning, carpet cleaning, office cleaning, deep cleaning, janitorial services",
        },
        {
          name: "author",
          content: "Imperial Solution",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0",
        },

        // Open Graph (redes sociales) en español e inglés
        {
          property: "og:title",
          content:
            "Imperial Solution | Servicios de Limpieza Comercial y de Alfombras | Commercial and Carpet Cleaning Services",
        },
        {
          property: "og:description",
          content:
            "Imperial Solution proporciona servicios de limpieza profesional para oficinas, áreas comunes y alfombras en el área de Seattle y sus alrededores. Imperial Solution provides professional cleaning services for offices and carpets in the Seattle area and beyond.",
        },
        {
          property: "og:image",
          content:
            "https://www.imperial-solution.com/img/imperial-solution-logo.png",
        },
        {
          property: "og:url",
          content: "https://www.imperial-solution.com",
        },
        {
          property: "og:type",
          content: "website",
        },

        // Twitter Card en español e inglés
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content:
            "Imperial Solution | Servicios de Limpieza Comercial y de Alfombras | Commercial and Carpet Cleaning Services",
        },
        {
          name: "twitter:description",
          content:
            "Servicios de limpieza comercial y de alfombras en Bellevue, Seattle y áreas cercanas. Commercial and carpet cleaning services in Bellevue, Seattle and surrounding areas.",
        },
        {
          name: "twitter:image",
          content:
            "https://www.imperial-solution.com/img/imperial-solution-logo.png",
        },
        {
          name: "twitter:site",
          content: "@imperialsolution",
        },

        // SEO Local en español e inglés
        {
          name: "geo.region",
          content: "US-WA",
        },
        {
          name: "geo.placename",
          content: "Seattle, Bellevue, Snohomish, Mount Vernon, Everett",
        },
        {
          name: "geo.position",
          content: "47.6062;-122.3321",
        },
        {
          name: "ICBM",
          content: "47.6062, -122.3321",
        },

        // Robots y otros
        {
          name: "robots",
          content: "index, follow",
        },
        {
          name: "googlebot",
          content: "index, follow",
        },
      ],
      link: [{ rel: "canonical", href: "https://www.imperial-solution.com" }],
    });
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  width: 100%;
  max-height: 300px;
  background-color: #000000;

  img {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .text-overlay {
    position: absolute;
    width: 75%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 36px;
    background-color: rgba($color: #000000, $alpha: 0.4);
    padding: 10px;
    border-radius: 5px;
  }
}

.services-container {
  background-color: #000000;
  width: 100%;
  height: auto;
  font-size: 24px;
  color: white;
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }
  .services {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: auto;
    .service-container {
      display: flex;
      position: relative;
      width: 400px;
      height: 400px;
      justify-content: space-around;
      align-items: center;

      .image,
      .image-hover {
        width: 350px;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity 0.3s ease-in-out;
      }

      .image-hover {
        opacity: 0;
      }
    }
    .service-container:hover {
      .image-hover {
        opacity: 1;
      }
      .image {
        opacity: 0;
      }
    }
  }
}

.division {
  width: 100%;
  height: 2px;
  border: solid #eeb84f 1px;
}
</style>
